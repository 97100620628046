import React from 'react'
import ImageLoader from './image-loader'
import { getArtistUrl } from '../lib/helpers'
import { Link } from 'gatsby'
import classNames from 'classnames'

function MusicGridItem(props) {
  const {
    kind,
    title,
    artist,
    releaseID,
    artistType,
    coverImage,
    previewImage,
    streamingLink,
    slug,
    isInMobileSlider,
  } = props
  const image = previewImage ? previewImage : coverImage
  const artistDefault = 'Warp Publishing'

  const renderIntroText = kind => {
    if (kind === 'releases') {
      return releaseID ? `latest release_${releaseID}:` : `latest release:`
    } else if (kind === 'monthly') {
      return `monthly playlist:`
    } else if (kind === 'selected') {
      return artistType ? `selected ${artistType}:` : `selected artist:`
    }
  }

  const getAltText = (title, artist) => {
    if (title && artist) {
      return `Cover image for ${title}, ${artist}`
    }
    if (title) {
      return `Cover image for ${title}`
    }
    if (artist) {
      return `Cover image for ${artist}`
    }
    return 'Cover image'
  }

  return (
    <div
      className={`
        music-grid-item
        is-flex
        image-filter
        ${isInMobileSlider ? 'p-0' : 'p-1'}
        ${kind === 'selected' ? 'is-half is-half-mobile' : 'is-one-quarter'}
        ${props?.className ?? ''}
       `}
    >
      {isInMobileSlider && <div className="music-grid-item__slider-border"></div>}
      <div className={`music-grid-item__title ${isInMobileSlider ? '' : 'mb-1_1'}`}>
        {kind && <div>{renderIntroText(kind)}</div>}
        {kind !== 'selected' && <h3 className="is-artist">{artist ? artist : artistDefault}</h3>}
        {title && <h3 className={kind !== 'selected' ? 'is-italic' : ''}>{title}</h3>}
      </div>
      {kind === 'selected' && slug ? (
        <>
          {isInMobileSlider ? (
            <div className="music-grid-item__image is-selected-artists">
              {image && (
                <ImageLoader
                  imageData={image}
                  widths={[320, 375, 750, 1024, 1280]}
                  alt={getAltText(artist, title)}
                  style={{ objectFit: 'cover' }}
                />
              )}
            </div>
          ) : (
            <Link
              className="music-grid-item__image is-selected-artists rounded-button__hover-area"
              to={getArtistUrl(slug)}
            >
              <div className="image-filter__image-wrapper">
                {image && (
                  <ImageLoader
                    imageData={image}
                    widths={[320, 375, 750, 1024, 1280]}
                    alt={getAltText(artist, title)}
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </div>
              <div className="rounded-button legibility-shadow text-caps --link-internal">view</div>
            </Link>
          )}
        </>
      ) : (
        <>
          {isInMobileSlider ? (
            <div className="music-grid-item__image image-loader--wrapper">
              {image && (
                <ImageLoader
                  imageData={image}
                  widths={[320, 375, 750, 1024, 1280]}
                  alt={getAltText(artist, title, kind)}
                  style={{ objectFit: 'cover' }}
                  preventDrag
                />
              )}
            </div>
          ) : (
            <a
              className="music-grid-item__image rounded-button__hover-area"
              href={streamingLink ? streamingLink : '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-filter__image-wrapper">
                {image && (
                  <ImageLoader
                    imageData={image}
                    widths={[375, 750, 1024, 1280]}
                    alt={getAltText(artist, title)}
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </div>
              <div className="rounded-button legibility-shadow text-caps --link-internal">
                Spotify
              </div>
            </a>
          )}
        </>
      )}
    </div>
  )
}

export default MusicGridItem
