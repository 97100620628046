import React, { useState, useRef, useEffect } from 'react'
import { isWarpC } from '../lib/helpers'
import FilterBar from './filter-bar'
import ProjectPreview from './project-preview'
import HeadlineLinking from './headline-linking'
import ProjectListOverlay from './project-list-overlay'
import classNames from 'classnames'
import LoadMore from './load-more'
import ListToggle from './icon/listToggle'
import RelatedProjectsSliderBlock from './related-projects-slider-block'

function SelectedProjectsBlock(props) {
  const { title, shortTitle, items, isHomepageView, hideFilter, linkToAll } = props
  const _title = title ? title : 'Selected Projects'
  const _shortTitle = shortTitle ? shortTitle : _title
  const hamburgerRef = useRef()
  const didMount = useRef(false)
  const [filter, setFilter] = useState(null)
  const [isListViewIn, setIsListViewIn] = useState(false)
  const [listViewScrollPosition, setListViewScrollPosition] = useState(0)
  const [gridViewScrollPosition, setGridViewScrollPosition] = useState(0)

  const categories = items.reduce(
    (collector, currentItem) => {
      const value = {
        _id: currentItem && currentItem.category._id,
        title: currentItem && currentItem.category.shortTitle,
      }
      if (!collector.some(item => item._id === value._id)) {
        collector.push(value)
      }
      return collector
    },
    [{ _id: null, title: 'All' }]
  )

  function handleListToggleCallback() {
    if (isListViewIn) {
      setListViewScrollPosition(window.scrollY)
      document.getElementById('content-wrapper').classList.remove('is-footer-hidden')
    } else {
      setGridViewScrollPosition(window.scrollY)
      document.getElementById('content-wrapper').classList.add('is-footer-hidden')
    }
    setIsListViewIn(!isListViewIn)
    hamburgerRef.current.toggleCross()
  }

  useEffect(() => {
    if (didMount.current) {
      if (isListViewIn) {
        window.scrollTo(0, listViewScrollPosition)
      } else {
        window.scrollTo(0, gridViewScrollPosition)
      }
    }
  }, [isListViewIn])

  useEffect(() => {
    didMount.current = true
    return () => {
      didMount.current = false
    }
  }, [])

  const itemsFiltered = items.filter(item => (filter ? item.category._id === filter : true))

  const renderHeadlineLinking = () => (
    <HeadlineLinking
      key={`SelectedProjectsBlock__HeadlineLinking`}
      buttonTo={linkToAll ? linkToAll.slug.current : ''}
      headlineContent={_title}
      headlineContentShort={_shortTitle}
      buttonContent="All Projects"
      darkTheme={isHomepageView && !isWarpC()}
    />
  )

  const renderFilterBar = () => (
    <FilterBar
      categories={categories}
      filter={filter}
      onClick={_id => {
        setFilter(_id)
      }}
    />
  )

  const renderPosterSlider = () => {
    const items = [{ title: _title, items: itemsFiltered }]
    return (
      <RelatedProjectsSliderBlock
        items={items}
        posterImage={true}
        hideCategoryTitles={true}
        className="selected-projects-block__poster-slider"
      />
    )
  }

  return (
    <>
      {isHomepageView
        ? renderHeadlineLinking()
        : !isWarpC() && <>{!hideFilter ? renderFilterBar() : <div />}</>}
      {isWarpC() && !isHomepageView && (
        <>
          <div className="selected-projects-block__wrapper__list-toggle no-border-top">
            <button
              className={`selected-projects-block__list-toggle rounded-button mob-m-1 ${classNames({
                'is-list-view-in': isListViewIn,
              })}`}
              onClick={handleListToggleCallback}
            >
              <span className="mob-none tab-pr-0_3">{isListViewIn ? 'Grid' : 'List'}</span>
              <ListToggle ref={hamburgerRef} stroke="black" strokeWidth="1" />
            </button>
          </div>
          <ProjectListOverlay isIn={isListViewIn} />
        </>
      )}
      {isWarpC() && isHomepageView && itemsFiltered ? (
        renderPosterSlider()
      ) : (
        <LoadMore
          initial={items.length}
          step={4}
          isHomepageView={isHomepageView}
          showButton={!isHomepageView}
          isListViewIn={isListViewIn}
          borderTop={false}
          className="no-border-top"
        >
          {itemsFiltered.map((item, index) => {
            return (
              item && (
                <ProjectPreview
                  key={`selected-projects-item-${item._id}+${index}`}
                  className={`column is-half is-full-mobile no-auto-border`}
                  {...item}
                  index={index}
                />
              )
            )
          })}
        </LoadMore>
      )}
      {/* {itemsFiltered.length < 2 && [...Array(2-itemsFiltered.length)].map((_, index) => <Filler/>)} */}
      {/* {isHomepageView && <MobilePreviewSlider slides={items} sliderType="projects" />} */}
    </>
  )
}

export default SelectedProjectsBlock
