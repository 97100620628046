import React, { useState } from 'react'
import { Link } from 'gatsby'
import { getProjectUrl, getArtistUrl } from '../lib/helpers'
import cx from 'classnames'

function LinksGridBlock({ items }) {
  const [currentHover, setCurrentHover] = useState(null)

  const getLinkData = item => {
    let link = item.route || item.link || '#'
    let useLinkComp = false
    if (item.route) {
      link = item.route
      useLinkComp = true
    }
    if (item.landingPageRoute && item.landingPageRoute.slug && item.landingPageRoute.slug.current) {
      link = item.landingPageRoute.slug.current
      useLinkComp = true
    } else if (item.projectRoute && item.projectRoute.slug && item.projectRoute.slug.current) {
      link = getProjectUrl(item.projectRoute.slug)
      useLinkComp = true
    } else if (item.artistRoute && item.artistRoute.slug && item.artistRoute.slug.current) {
      link = getArtistUrl(item.artistRoute.slug)
      useLinkComp = true
    }
    return {
      url: link,
      useLinkComp: useLinkComp,
    }
  }

  const renderItem = (item, i) => {
    const linkData = getLinkData(item)
    const classes = cx('links-grid-block column is-flex', {
      'is-hovered': currentHover === i,
    })
    return linkData.useLinkComp ? (
      <Link
        key={item._key}
        to={linkData.url}
        className={classes}
        onMouseEnter={() => setCurrentHover(i)}
        onMouseLeave={() => setCurrentHover(null)}
      >
        <span className="links-grid-block__content mob-m-1 tab-m-2_4">
          <span>{item.title}</span>
        </span>
      </Link>
    ) : (
      <a
        key={item._key}
        href={linkData.url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes}
        onMouseEnter={() => setCurrentHover(i)}
        onMouseLeave={() => setCurrentHover(null)}
      >
        <div className="links-grid-block__content mob-m-1 tab-m-2_4">
          <span>{item.title}</span>
        </div>
      </a>
    )
  }

  return (
    <div
      className={cx('links-grid-block columns text-l text-caps is-multiline is-gapless is-flex', {
        'is-hover-active': currentHover !== null,
      })}
    >
      {items.map((item, i) => renderItem(item, i))}
    </div>
  )
}

export default LinksGridBlock
