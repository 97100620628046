import React from 'react'
import CTALink from './CTALink'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import TextColumn from './text-column'

function TextColumnsBlock(props) {
  const { links, leftColumn, rightColumn } = props
  return (
    <div className="hero">
      {links &&
        links.map((link, i) => (
          <li key={link._key}>
            <CTALink {...link} />
          </li>
        ))}
      <div className="columns">
        {alert('here')}
        {leftColumn && <TextColumn {...leftColumn} />}
        {rightColumn && <TextColumn {...rightColumn} />}
      </div>
    </div>
  )
}

export default TextColumnsBlock
