import { Link } from 'gatsby'
import React, { useState } from 'react'
import { getProjectUrl, inheritClassNames, isBrowser } from '../lib/helpers'
import classNames from 'classnames'
import ImageLoader from './image-loader'
import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group'
import OverlayVideo from './overlay-video'
import modalStyle from '../styles/common/modal'
import { useEffect } from 'react'

const ProjectPreview = props => {
  const {
    title,
    artist,
    category,
    productionCompany,
    previewImage,
    slug,
    isInMobileSlider,
    index,
    indexDelay,
    isOverlayVideo,
    overlayVideoUrl,
  } = props

  const [modalOpen, setModalOpen] = useState(false)
  const onOpenModal = () => setModalOpen(true)
  const onCloseModal = () => setModalOpen(false)

  const containerClasses = classNames({
    'is-in-mobile-slider': isInMobileSlider,
    'project-preview--video-overlay-active': modalOpen,
  })

  const imgAlt = `Preview image for ${title}, ${artist ? artist.title : ''}`
  const imgStyle = { width: '100%', height: '100%', objectFit: 'cover' }
  const useOverlayVideo = isOverlayVideo && overlayVideoUrl

  useEffect(() => {
    if (isBrowser() && isOverlayVideo) {
      const slug = props.slug?.current
      const hash = window.location.hash
      if (slug && hash && hash === `#${slug}`) {
        setModalOpen(true)
      }
    }
  }, [])

  const LinkWrapper = ({ children }) => {
    return isOverlayVideo ? (
      <>
        <div
          className={`project-preview image-filter rounded-button__hover-area ${containerClasses} ${inheritClassNames(
            props
          )}`}
        >
          {children}
          <button
            className="project-preview__open-overlay"
            onClick={onOpenModal}
            aria-label="Open video overlay"
          />
        </div>
      </>
    ) : (
      <Link
        className={`project-preview image-filter rounded-button__hover-area ${containerClasses} ${inheritClassNames(
          props
        )}`}
        to={slug ? getProjectUrl(slug.current) : ''}
      >
        {children}
      </Link>
    )
  }

  const Button = () => {
    return useOverlayVideo ? (
      <span className="rounded-button legibility-shadow project-preview__view-button project-preview__view-button--open-video --link-watch">
        Play video
      </span>
    ) : (
      <span className="rounded-button legibility-shadow project-preview__view-button --link-internal">
        View project
      </span>
    )
  }

  const mobileSliderTitle = (
    <span>
      {title && <em>{title}</em>}, {category && <span>{category.title}</span>}
    </span>
  )

  const renderVideoOverlay = (title, artist, productionCompany, overlayVideoUrl) => {
    return (
      <CSSTransition in={modalOpen} timeout={400}>
        <Modal
          closeTimeoutMS={400}
          isOpen={modalOpen}
          onRequestClose={onCloseModal}
          style={modalStyle}
        >
          <OverlayVideo
            title={title}
            artist={artist}
            productionCompany={productionCompany}
            isProjectPreview
            videoUrl={overlayVideoUrl}
            onCloseModal={onCloseModal}
          />
        </Modal>
      </CSSTransition>
    )
  }

  const MobileSliderPreview = (
    <div
      className={`project-preview ${containerClasses} ${inheritClassNames(props)}`}
      to={slug ? getProjectUrl(slug.current) : ''}
    >
      <div className="project-preview__image image-loader--wrapper">
        <ImageLoader
          imageData={previewImage}
          widths={[320, 375, 750, 1024, 1280]}
          alt={imgAlt}
          style={imgStyle}
          preventDrag
        />
      </div>
      <h3 className="project-preview__title text-m text-caps mxs-p-0_4 mob-p-1">
        {artist && <span className="project-preview__title__artist">{artist.title}</span>}
        <span className="project-preview__title__title">{mobileSliderTitle}</span>
      </h3>
    </div>
  )

  return isInMobileSlider ? (
    <MobileSliderPreview />
  ) : (
    <>
      <LinkWrapper>
        <div className="project-preview__image image-filter__image-wrapper">
          <ImageLoader
            imageData={previewImage}
            widths={[750, 1024, 1280, 1400, 1920, 2400]}
            alt={imgAlt}
            style={imgStyle}
            index={index}
            indexDelay={indexDelay}
          />
        </div>
        <div className="project-preview__title legibility-shadow text-m-minus text-caps image-filter__grayscale mob-p-1">
          {artist && <span className="project-preview__title__artist">{artist.title}</span>}
          {title && <span className="project-preview__title__title">{title}</span>}
          {productionCompany && <span>{productionCompany}</span>}
        </div>
        <Button />
      </LinkWrapper>
      {overlayVideoUrl &&
        renderVideoOverlay(title, artist.title, productionCompany, overlayVideoUrl)}
    </>
  )
}

export default ProjectPreview
