import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import clientConfig from '../../client-config'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import HeadlineLinking from './headline-linking'
import { isWarpC } from '../lib/helpers'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import ListBlockTooltip from './list-block-tooltip'
import cx from 'classnames'

class SingleColumnArtistListBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipPos: 'top',
      currentImageId: null,
      currentArtistName: null,
      isMouseTooltipVisible: false,
    }
    this.onMouseEnterArtistName = this.onMouseEnterArtistName.bind(this)
    this.onMouseLeaveArtistName = this.onMouseLeaveArtistName.bind(this)
  }

  onMouseEnterArtistName(currentImageId, newCurrentArtistName) {
    if (currentImageId) {
      const currentImageData = getGatsbyImageData(
        currentImageId,
        {
          fit: 'fillmax',
          placeholder: 'blurred',
          quality: 1,
          width: 400,
        },
        clientConfig.sanity
      )
      // const currentPlaceholderImageData = getGatsbyImageData(
      //   currentImageId,
      //   {
      //     fit: 'fillmax',
      //     placeholder: 'blurred',
      //     width: 400,
      //   },
      //   clientConfig.sanity
      // )
      this.setState({
        currentImageData,
        // currentPlaceholderImageData,
        currentArtistName: newCurrentArtistName,
        isMouseTooltipVisible: true,
      })
    }
  }

  onMouseLeaveArtistName(e) {
    this.setState({ isMouseTooltipVisible: false })
  }

  updateTooltipPos(e) {
    let pos
    if (e.clientY > window.innerHeight / 2) {
      pos = 'bottom'
    } else {
      pos = 'top'
    }
    this.setState({ tooltipPos: pos })
  }

  render() {
    const artistsRoute = isWarpC() ? 'composers' : 'artists'
    const headlineContent = isWarpC() ? 'Composers' : 'Artists'
    const buttonContent = isWarpC() ? 'All Composers' : 'All Artists'
    const linkToAllDefault = isWarpC() ? '/composers' : '/artists'
    const { isHomepageView, artistNodes } = this.props
    const ulClasses = classNames({
      [`is-tooltip-pos-${this.state.tooltipPos}`]: true,
    })
    return (
      <>
        {isHomepageView && (
          <HeadlineLinking
            buttonTo={this.props.linkToAll ? this.props.linkToAll.slug.current : linkToAllDefault}
            headlineContent={headlineContent}
            buttonContent={buttonContent}
          />
        )}
        <div
          className={cx('single-column-artist-list-block', { 'is-index-view': !isHomepageView })}
        >
          <ul
            className={`single-column-artist-list-block__inner ${ulClasses}`}
            onMouseMove={e => this.updateTooltipPos(e)}
          >
            {artistNodes.map((artist, i) => {
              let callbacks = {}
              if (!isMobile && artist.previewImage) {
                callbacks.onMouseEnter = () => {
                  if (artist.previewImage) {
                    this.onMouseEnterArtistName(artist.previewImage.asset._id, artist.title)
                  }
                }
                callbacks.onMouseLeave = this.onMouseLeaveArtistName
              }
              return (
                <li className="single-column-artist-list-block__item" key={artist.id}>
                  <Link
                    to={`/${artistsRoute}/${artist.slug.current}`}
                    data-name={artist.title}
                    {...callbacks}
                  >
                    {artist.title}
                  </Link>
                </li>
              )
            })}
            <ListBlockTooltip
              currentImageData={this.state.currentImageData}
              // currentPlaceholderImageData={this.state.currentPlaceholderImageData}
              isMouseTooltipVisible={this.state.isMouseTooltipVisible}
              currentTitle={this.state.currentArtistName}
            />
          </ul>
        </div>
      </>
    )
  }
}

export default SingleColumnArtistListBlock
