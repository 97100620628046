import React, { Component } from 'react'
import HeadlineColumn from './headline-column'
import StackingColumn from './stacking-column'

const HeadlinedStackingColumnBlock = props => {
  const shortTitle = props.leftColumn.title && props.leftColumn.title.length < 20

  return (
    <div
      className="headlined-stacking-column-block columns grow-last-vertically"
      id={props.id ? props.id : ''}
    >
      <HeadlineColumn className="headlined-stacking-column-block__headline column is-6">
        <h2
          className={`text-l text-caps mob-pt-1 mob-pl-1 mob-pr-1 mob-pb-0_5 tab-p-2_4 ${
            shortTitle ? 'is-short-title' : ''
          }`}
        >
          <span>
            <span className="arrowDownRight--left"></span>
            <span>
              <span>{props.leftColumn.title}</span>
            </span>
          </span>
        </h2>
      </HeadlineColumn>
      <StackingColumn className="column is-6" column={props.rightColumn} />
    </div>
  )
}

export default HeadlinedStackingColumnBlock
