import React, { useState, useEffect, useRef, useCallback } from 'react'
import PortableText from './portableText'
import ImageLoader from './image-loader'
import { Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { navigate } from 'gatsby'
import ReactDOMServer from 'react-dom/server'
import { isWarpC, isBrowser, vhMinusNav, getArtistUrl, getProjectUrl } from '../lib/helpers'
import classNames from 'classnames'
import Vimeo from '@u-wave/react-vimeo'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/a11y'

function HeroSliderBlock(props) {
  const { heroSlides, introVideoUrl, introVideoOptions, hidden } = props
  const [swiper, setSwiper] = useState(null)
  const [swiperVisible, setSwiperVisible] = useState(false)
  const [themeBgActive, setThemeBgActive] = useState(false)
  const [videoReady, setVideoReady] = useState(false)
  const [videoPaused, setVideoPaused] = useState(false)
  const swiperContainer = useRef(null)
  const height = vhMinusNav()

  const titles = heroSlides.map((slide, i) => {
    if (i === 0) {
      return ''
    } else if (slide.projectRoute) {
      return 'View project'
    } else if (slide.artistRoute) {
      return 'View artist'
    } else {
      return 'View'
    }
  })

  const links = heroSlides.map((slide, i) => {
    if (slide.landingPageRoute) {
      return {
        url: slide.landingPageRoute.slug.current,
        external: false,
      }
    } else if (slide.artistRoute) {
      return {
        url: getArtistUrl(slide.artistRoute.slug.current),
        external: false,
      }
    } else if (slide.projectRoute) {
      return {
        url: getProjectUrl(slide.projectRoute.slug.current),
        external: false,
      }
    } else if (slide.link) {
      return {
        url: slide.link,
        external: true,
      }
    }
  })

  const onSlideClick = (e, i) => {
    if (i !== swiper.realIndex) {
      e.preventDefault()
      swiper.slideNext()
    }
  }

  const onVideoReady = () => {
    if (!videoReady) {
      setVideoReady(true)
      if (introVideoOptions === 'fixedLength') {
        setTimeout(() => {
          setVideoPaused(true)
        }, 10000)
      }
    }
  }

  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      const link = links[index] ? links[index] : ''
      return ReactDOMServer.renderToString(
        <button
          className={`rounded-button ${className} ${classNames({
            'is-bg-themed': !heroSlides[index].image,
          })}`}
          data-ref="slider-button"
          data-link={link.url}
          data-external={link.external}
          role={link ? 'link' : null}
        >
          <span>{titles[index] || ''}</span>
        </button>
      )
    },
  }

  const swiperSettings = {
    modules: [Pagination, A11y, Autoplay],
    enabled: heroSlides && heroSlides.length > 1,
    initialSlide: 0,
    slidesPerView: 1,
    centeredSlides: false,
    loop: true,
    preventClicks: false,
    onSwiper: setSwiper,
    pagination: pagination,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    onSlideChange: e => {
      setThemeBgActive(heroSlides[e.realIndex].image === undefined)
    },
  }

  const onSliderButtonClick = useCallback(e => {
    const el = e.target
    if (el.classList.contains('swiper-pagination-bullet-active')) {
      if (el.dataset.link) {
        if (el.dataset.external === 'true') {
          window.open(el.dataset.link, '_blank')
        } else {
          navigate(el.dataset.link)
        }
      }
    }
  }, [])

  const getSliderButtons = () => {
    if (!hidden) {
      return swiperContainer.current.querySelectorAll('[data-ref="slider-button"]')
    }
  }

  useEffect(() => {
    if (!hidden) {
      const handleResize = () => {
        const buttons = getSliderButtons()
        buttons.forEach(el => {
          el.addEventListener('click', onSliderButtonClick)
        })
      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!hidden) {
      const buttons = getSliderButtons()
      buttons.forEach(el => {
        el.addEventListener('click', onSliderButtonClick)
        return () => {
          el.removeEventListener('click', onSliderButtonClick)
        }
      })
    }
  }, [onSliderButtonClick])

  useEffect(() => {
    if (swiper) {
      swiper.startAutoplay()
    }
  }, [])

  useEffect(() => {
    setSwiperVisible(true)
  }, [swiper])

  const renderIntro = (slideTitle, slideText, className = '') => {
    return (
      <div className={`hero-slider-block__intro ${className} mob-p-1 mob-pt-1_1 tab-p-2_4`}>
        <div className="hero-slider-block__intro__animation-wrapper">
          {slideTitle && <div className="title-lg hero-slider-block__title">{slideTitle}</div>}
          {slideText && (
            <div className="title-lg">{slideText && <PortableText blocks={slideText} />}</div>
          )}
        </div>
      </div>
    )
  }

  const renderSlideContent = (slide, index) => {
    const { slideTitle, slideText, image, textColor } = slide
    const classes = classNames({ [`is-text-${textColor}`]: textColor })
    const alt =
      image?.alt ?? `${isWarpC() ? 'Warp Composers' : 'Warp Publishing'} - intro slide ${index + 1}`
    if (introVideoUrl) {
      return (
        <div className="hero-slider-block__video-container" data-slide-index={index}>
          <ImageLoader
            className="hero-slider-block__video-placeholder"
            imageData={image}
            alt={alt}
            widths={[320, 375, 750, 1024, 1280, 1920, 2400]}
            preventDrag
          />
          {introVideoUrl && introVideoOptions !== 'inactive' && (
            <Vimeo
              className="hero-slider-block__video-player"
              video={introVideoUrl}
              autoplay
              background
              muted
              loop
              paused={videoPaused}
              onPlay={() => onVideoReady()}
            />
          )}
          {renderIntro(slideTitle, slideText, `hero-slider-block__intro--video ${classes}`)}
        </div>
      )
    } else if (image && index !== 0) {
      return (
        <div className="hero-slider-block__image">
          <ImageLoader
            imageData={image}
            alt={alt}
            widths={[320, 375, 750, 1024, 1280, 1920, 2400]}
            preventDrag
          />
          {renderIntro(slideTitle, slideText, `hero-slider-block__intro--image ${classes}`)}
        </div>
      )
    } else {
      return <>{renderIntro(slideTitle, slideText)}</>
    }
  }

  return !hidden ? (
    <div
      className={`hero-slider-block  text-caps ${classNames({
        'theme-bg-active': themeBgActive,
        'is-swiper-visible': swiperVisible,
        'is-video-init': videoReady && !videoPaused,
      })}`}
      style={isBrowser() ? { height: height } : {}}
      ref={swiperContainer}
    >
      <Swiper {...swiperSettings}>
        {heroSlides &&
          heroSlides.map((slide, index) => {
            return (
              <SwiperSlide
                className={`hero-slider-block__slide`}
                onClick={e => onSlideClick(e, index)}
                key={slide._key}
              >
                {renderSlideContent(slide, index)}
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}

export default HeroSliderBlock
