import React from 'react'

const strokeStyle = { vectorEffect: 'non-scaling-stroke' }

class ListToggle extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isCloseButton: false, clicked: false }
    this.toggleCross = this.toggleCross.bind(this)
    this.hamburger = this.hamburger.bind(this)
    this.cross = this.cross.bind(this)
    this.hamburgerRef = React.createRef()
  }

  hamburger() {
    this.setState({
      isCloseButton: false,
      clicked: true,
    })
    this.hamburgerRef.current.querySelectorAll('animate').forEach(animate => {
      animate.beginElement()
    })

  }

  cross() {
    this.setState({
      isCloseButton: true,
      clicked: true,
    })
    this.hamburgerRef.current.querySelectorAll('animate').forEach(animate => {
      animate.beginElement()
    })

  }

  toggleCross() {
    this.setState({
      isCloseButton: !this.state.isCloseButton,
      clicked: true,
    })
    this.hamburgerRef.current.querySelectorAll('animate').forEach(animate => {
      animate.beginElement()
    })
  }

  path(values, index) {
    const stroke = this.props.stroke || 'black'
    const duration = `${this.props.duration || 0.1}s`
    const values_ = this.state.isCloseButton ? values : values.reverse()
    const strokeWidth = this.props.strokeWidth || 1
    return (
      <path key={index} fill="none" stroke={stroke} strokeWidth={strokeWidth} {...strokeStyle}>
        <animate attributeName="d" values={values_.join(';')} dur={duration} fill="freeze" />
      </path>
    )
  }

  svg(animateValues) {
    return (
      <svg
        ref={this.hamburgerRef}
        className={`hamburger`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 20"
      >
        {animateValues.map((animateValue, index) => this.path(animateValue, index))}
      </svg>
    )
  }

  render() {
    const animateValues = [
      ['M0 0  L4 0',  'M0 0  L11.5 0'],
      ['M0 10  L4 10', 'M0 10  L11.5 10'],
      ['M0 20  L4 20', 'M0 20  L11.5 20'],
      ['M9 0  L28 0',  'M16.5 0  L28 0'],
      ['M9 10  L28 10', 'M16.5 10  L28 10'],
      ['M9 20  L28 20', 'M16.5 20  L28 20'],
    ]
    // const animateValues = [
    //   ['M0 0  L28 0', 'M0 10  L28 10', 'M4 20  L24 0'],
    //   ['M0 10  L28 10', 'M0 10  L28 10', 'M4 20  L24 0'],
    //   ['M0 20  L28 20', 'M0 10  L28 10', 'M4 0  L24 20'],
    // ]
    return this.state.clicked
      ? this.svg(animateValues)
      : this.svg(animateValues.map(values => values.splice(0, 1)))
  }
}

export default ListToggle
