import React, { useEffect, useRef, useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { isMobile } from 'react-device-detect'
import { format } from 'date-fns'
import classNames from 'classnames'
import clientConfig from '../../client-config'
import ListBlockTooltip from './list-block-tooltip'
import { isWarpC, mapEdgesToNodes } from '../lib/helpers'
import { getProjectUrl } from '../lib/helpers'

export const projectListOverlayQuery = graphql`
  query ProjectListOverlayQuery {
    projects: allSanityProject(
      sort: { fields: [releaseDate], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true }, isIndexed: { eq: true } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...SanityImage
            alt
          }
          title
          artist {
            title
          }
          directors
          releaseDate
          slug {
            current
          }
        }
      }
    }
  }
`

const ProjectListOverlay = props => {
  const [currentImageData, setCurrentImageData] = React.useState(null)
  const [currentProjectTitle, setCurrentProjectTitle] = React.useState(null)
  const [isMouseTooltipVisible, setIsMouseTooltipVisible] = React.useState(false)
  const [tooltipPos, setTooltipPos] = React.useState('top')

  const onMouseEnterProjectTitle = (currentImageId, newCurrentProjectTitle) => {
    setCurrentImageData(
      getGatsbyImageData(
        currentImageId,
        {
          width: 400,
          fit: 'fillmax',
          placeholder: 'blurred',
        },
        clientConfig.sanity
      )
    )
    setCurrentProjectTitle(newCurrentProjectTitle)
    setIsMouseTooltipVisible(true)
  }

  const onMouseLeaveProjectTitle = () => {
    setIsMouseTooltipVisible(false)
  }

  const updateTooltipPos = e => {
    if (e.clientY > window.innerHeight / 2) {
      setTooltipPos('bottom')
    } else {
      setTooltipPos('top')
    }
  }

  return (
    <StaticQuery
      query={projectListOverlayQuery}
      render={data => {
        const projects = mapEdgesToNodes(data.projects)
        const ulClasses = classNames({
          [`is-tooltip-pos-${tooltipPos}`]: true,
        })
        return (
          <div className={`project-list-overlay ${props.isIn ? 'is-in' : ''} ${ulClasses}`}>
            <ul onMouseMove={e => updateTooltipPos(e)}>
              {projects.map((project, i) => {
                const { id, title, directors, artist, releaseDate, slug, previewImage } = project
                let callbacks = {}
                if (!isMobile && previewImage) {
                  callbacks.onMouseEnter = () => {
                    onMouseEnterProjectTitle(previewImage.asset._id, project.title)
                  }
                  callbacks.onMouseLeave = onMouseLeaveProjectTitle
                }
                return (
                  <li key={id}>
                    <Link to={getProjectUrl(slug)} {...callbacks}>
                      <span>
                        {title && <span className="text-caps">{title}</span>},&nbsp;
                        {directors && (
                          <>
                            <span>dir. {directors},</span>
                            &nbsp;
                          </>
                        )}
                        {releaseDate && <span>{format(releaseDate, 'YYYY')}</span>}
                      </span>
                      <span>
                        {artist && (
                          <>
                            <span>Original Score by</span>
                            &nbsp;
                            <span className="text-caps">{artist.title}</span>
                          </>
                        )}
                      </span>
                    </Link>
                  </li>
                )
              })}
              <ListBlockTooltip
                currentImageData={currentImageData}
                isMouseTooltipVisible={isMouseTooltipVisible}
                currentTitle={currentProjectTitle}
              />
            </ul>
          </div>
        )
      }}
    ></StaticQuery>
  )
}

export default ProjectListOverlay
