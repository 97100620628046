import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated, to } from 'react-spring'
import ArtistsParallaxItem from './artists-parallax-item'
import TextFilter from './text-filter'
import { getWindowDimensions, isBrowser } from '../lib/helpers'
import { shuffle } from 'lodash'
import { Link } from 'gatsby'
import SelectedArtistsBlock from './selected-artists-block'

const ArtistsParallaxBlock = props => {
  const { title, items } = props
  const [itemsRandom, setItemsRandom] = useState(null)
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null)
  const [springProps, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  // mobile scroll parallax
  const [scrollOffset, setScrollOffset] = useState(0)

  const isItemLandscape = item =>
    item.previewImage && item.previewImage.asset.metadata.dimensions.aspectRatio >= 1

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

  const onMouseMove = ({ clientX: x, clientY: y }) => {
    setTimeout(() => {
      set({ xy: calc(x, y * 0.666) })
    }, 1000 / 20)
  }

  const onMouseEnterItem = index => {
    setHoveredItemIndex(index)
  }

  const onMouseLeaveItem = () => {
    setHoveredItemIndex(null)
  }

  const getOffset = (x, y, i) => {
    const { width, height } = windowDimensions
    let offset
    switch (i) {
      case 0:
        offset = {
          x: x / 10,
          y: y / 10,
        }
        break
      case 1:
        offset = {
          x: x / 8 + width * 0.35,
          y: y / 8 - height * 0.1,
        }
        break
      case 2:
        offset = {
          x: x / 7 - width * 0.4,
          y: y / 7 - height * 0.2,
        }
        break
      case 3:
        offset = {
          x: x / 6 + width * 0.15,
          y: y / 6 - height * 0.3,
        }
        break
      case 4:
        offset = {
          x: x / 8 - width * 0.25,
          y: y / 8 + height * 0.2,
        }
        break
      case 5:
        offset = {
          x: x / 7 - width * 0.2,
          y: y / 7 - height * 0.25,
        }
        break
      case 6:
        offset = {
          x: x / 6 + width * 0.22,
          y: y / 6 + height * 0.26,
        }
        break
      case 7:
        offset = {
          x: x / 7 - width * 0.08,
          y: y / 8 + height * 0.33,
        }
        break
      case 8:
        offset = {
          x: x / 7 + width * 0.4,
          y: y / 7 + height * 0.2,
        }
        break
      case 9:
        offset = {
          x: x / 6 - width * 0.4,
          y: y / 6 + height * 0.3,
        }
        break
      default:
        offset = {
          x: x / 10,
          y: y / 10,
        }
    }
    return offset
  }

  useEffect(() => {
    items && setItemsRandom(shuffle(items))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      const handleScroll = () => {
        setScrollOffset(window.pageYOffset)
      }
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const renderInnerDesktop = () => (
    <div
      className="artists-parallax-block__parallax-inner artists-parallax-block__parallax-inner--desktop"
      onMouseMove={onMouseMove}
    >
      {itemsRandom &&
        itemsRandom.map((item, index) => {
          const isLandscape = isItemLandscape
          const trans = (x, y, i = index) => {
            let offset
            if (!isBrowser()) {
              offset = {
                x: 0,
                y: 0,
              }
            } else {
              offset = getOffset(x, y, i)
            }
            return `translate3d(${-offset.x}px,${-offset.y}px,0)`
          }
          return (
            <animated.div
              key={item._id}
              className={`artists-parallax-block__parallax-item-container is-${
                isLandscape ? 'landscape' : 'portrait'
              }`}
              style={{
                transform: to(springProps.xy, trans),
              }}
            >
              <ArtistsParallaxItem
                {...item}
                isLandscape={isLandscape}
                index={index}
                useMouseEvents
                onMouseEnterItem={onMouseEnterItem}
                onMouseLeaveItem={onMouseLeaveItem}
                hoveredItemIndex={hoveredItemIndex}
              />
            </animated.div>
          )
        })}
    </div>
  )

  const renderInnerMobile = () => (
    <>
      <Link to={'/artists'} className="artists-parallax__link-all"></Link>
      <div className="artists-parallax-block__parallax-inner artists-parallax-block__parallax-inner--mobile">
        {itemsRandom &&
          itemsRandom.slice(0, 5).map((item, index) => {
            const isLandscape =
              item.previewImage && item.previewImage.asset.metadata.dimensions.aspectRatio >= 1
            const negative = Boolean(Math.round(Math.random()))
            const transformUp = [0, 2, 5]
            const tInitial = (scrollOffset * index) / 33 - 10
            const tBoost = tInitial + 10
            const transform = `translate3d(0px, ${
              transformUp.includes(index) ? '-' + tInitial : tBoost
            }px, 0px)`
            return (
              <div
                key={item._id}
                className={`artists-parallax-block__parallax-item-container is-mobile is-${
                  isLandscape ? 'landscape' : 'portrait'
                }`}
                data-index={index}
                data-negative={negative}
                style={
                  scrollOffset
                    ? {
                        transform: transform,
                        WebkitTransform: transform,
                      }
                    : {}
                }
              >
                <ArtistsParallaxItem
                  {...item}
                  isLandscape={isLandscape}
                  index={index}
                  isLink={false}
                />
              </div>
            )
          })}
      </div>
    </>
  )

  return (
    <>
      <div className="artists-parallax-block artists-parallax-block--parallax">
        {title && (
          <TextFilter
            className="artists-parallax-block__parallax-title text-l text-caps"
            content={title}
          />
        )}
        {renderInnerDesktop()}
        {renderInnerMobile()}
      </div>
    </>
  )
}

export default ArtistsParallaxBlock
