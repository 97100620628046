import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import AnimateHeight from 'react-animate-height'
import { isWarpC } from '../lib/helpers'

const transitionDuration = 550

var Scroll = require('react-scroll')
const scroll = Scroll.animateScroll

function LoadMore(props) {
  const {
    initial,
    step,
    isListViewIn,
    showButton,
    isGapLess = false,
    borderTop = true,
    className,
  } = props
  const [limit, setLimit] = useState(initial)
  // const [limit, setLimit] = useState(1)
  const didMount = useRef(false)
  const containerRef = useRef()
  const [currentHeight, setCurrentHeight] = useState('auto')
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(
    showButton && props.children.length > limit
  )
  const [renderLoadMoreButton, setRenderLoadMoreButton] = useState(showLoadMoreButton)

  useEffect(() => {
    if (didMount.current && currentHeight?.position === 'start') {
      setLimit(limit + step)
    } else if (currentHeight?.position === 'end') {
    }
  }, [currentHeight])

  useEffect(() => {
    if (showButton) {
      setShowLoadMoreButton(props.children.length > limit)
    }
  }, [props.children])

  useEffect(() => {
    if (didMount.current) {
      const { scrollHeight, offsetHeight } = containerRef.current
      scroll.scrollMore(scrollHeight - offsetHeight, {
        smooth: 'easeOutQuart',
        duration: transitionDuration,
      })
      setCurrentHeight({ height: scrollHeight, position: 'end' })
      setTimeout(() => {
        setCurrentHeight(null)
      }, transitionDuration)
    }
  }, [limit])

  useEffect(() => {
    didMount.current = true
  }, [])

  function handleOnLoadMoreClick() {
    const { offsetHeight } = containerRef.current
    setCurrentHeight({ height: offsetHeight, position: 'start' })
  }

  return (
    <div
      className={`
      ${className || ''}
      ${classNames({
        'is-list-view-in': isListViewIn,
      })}`}
    >
      <div
        ref={containerRef}
        className={`load-more__items columns tab-mb-0 is-multiline no-border-top is-flex ${classNames(
          { 'is-gapless': isGapLess }
        )}`}
        style={{
          height: currentHeight ? `${currentHeight.height}px` : 'auto',
          overflow: 'hidden',
          transition: `height ${transitionDuration}ms cubic-bezier(0.25, 1, 0.5, 1)`,
        }}
      >
        {props.children.filter((_, index) => index < limit)}
        {props.children.length % 2 !== 0 && (
          <div className={`filler ${!isWarpC() && 'border-left-single'}`}></div>
        )}
      </div>
      {renderLoadMoreButton && (
        <AnimateHeight
          height={props.children.length > limit && showLoadMoreButton ? 'auto' : 0}
          className={`load-more__button-wrapper  ${classNames({
            'is-list-view-in': isListViewIn,
            'border-top-single': borderTop,
          })}`}
          onAnimationEnd={() => setRenderLoadMoreButton(false)}
        >
          <button
            onClick={handleOnLoadMoreClick}
            className={`rounded-button load-more__button text-m mob-p-1_2  ${classNames({
              'border-top-single': borderTop,
            })}`}
            disabled={!showLoadMoreButton}
          >
            load more
          </button>
        </AnimateHeight>
      )}
    </div>
  )
}

export default LoadMore
