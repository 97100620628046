import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getArtistUrl, getProjectUrl } from '../lib/helpers'
import ProjectPreview from './project-preview'
import MusicGridItem from './music-grid-item'
import { navigate } from 'gatsby'
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames'
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/pagination'

const MobilePreviewSlider = props => {
  const { slides, sliderType, musicGridKind, className } = props
  const [swiper, setSwiper] = useState(null)
  const swiperContainer = useRef(null)

  const links = slides.map((slide, i) => {
    let link = {
      url: '',
      external: false,
    }
    switch (slide._type) {
      case 'project':
        link = {
          url: getProjectUrl(slide.slug),
          external: false,
        }
        break
      case 'artist':
        link = {
          url: getArtistUrl(slide.slug),
          external: false,
        }
        break
      case 'album':
        link = {
          url: slide.streamingLink ? slide.streamingLink : '',
          external: true,
        }
        break
      case 'musicGridPlaylist':
        link = {
          url: slide.streamingLink ? slide.streamingLink : '',
          external: true,
        }
        break
      default:
        link = ''
    }
    return link
  })

  const onSlideClick = (e, i) => {
    if (i !== swiper.realIndex) {
      e.preventDefault()
      swiper.slideNext()
    }
  }

  const onSliderButtonClick = useCallback(e => {
    const el = e.target
    if (el.classList.contains('swiper-pagination-bullet-active')) {
      if (el.dataset.link) {
        if (el.dataset.external === 'true') {
          window.open(el.dataset.link, '_blank')
        } else {
          navigate(el.dataset.link)
        }
      }
    }
  }, [])

  let buttonText, linkSymbolModifier

  switch (sliderType) {
    case 'projects':
      buttonText = 'View Project'
      linkSymbolModifier = '--link-internal'
      break
    case 'artists':
      buttonText = 'View Artist'
      linkSymbolModifier = '--link-internal'
      break
    case 'musicGrid':
      if (musicGridKind === 'selected') {
        buttonText = 'View Artist'
        linkSymbolModifier = '--link-internal'
      } else {
        buttonText = 'Listen'
        linkSymbolModifier = '--link-external'
      }
      break
    default:
      buttonText = ''
      linkSymbolModifier = ''
  }

  const pagination = {
    clickable: true,
    renderBullet: function(index, className) {
      const link = links[index] ? links[index] : ''
      return ReactDOMServer.renderToString(
        <button
          className={`rounded-button mobile-preview-slider__slider-button slider-button ${className}`}
          data-ref="slider-button"
          data-link={link.url}
          data-external={link.external}
          role="link"
        >
          <span className={linkSymbolModifier}>{buttonText}</span>
        </button>
      )
    },
  }

  const swiperSettings = {
    modules: [Pagination, A11y],
    initialSlide: 0,
    slidesPerView: 1,
    centeredSlides: false,
    loop: true,
    preventClicks: false,
    onSwiper: setSwiper,
    pagination: pagination,
  }

  const getSliderButtons = () => {
    return swiperContainer.current.querySelectorAll('[data-ref="slider-button"]')
  }

  useEffect(() => {
    const handleResize = () => {
      const buttons = getSliderButtons()
      buttons.forEach(el => {
        el.addEventListener('click', onSliderButtonClick)
      })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const buttons = getSliderButtons()
    buttons.forEach(el => {
      el.addEventListener('click', onSliderButtonClick)
      return () => {
        el.removeEventListener('click', onSliderButtonClick)
      }
    })
  }, [onSliderButtonClick])

  const renderSlideItem = slide => {
    let el = null
    switch (sliderType) {
      case 'projects':
        el = <ProjectPreview {...slide} isInMobileSlider />
        break
      case 'musicGrid':
        el = <MusicGridItem {...slide} isInMobileSlider />
        break
      default:
        el = null
    }
    return el
  }

  const classes = classNames({
    'is-music-grid': sliderType === 'musicGrid',
    'is-projects': sliderType === 'projects',
    'is-selected-artists': musicGridKind === 'selected',
    [`${className}`]: className,
  })

  return (
    <div
      className={`mobile-preview-slider no-border-top no-border-bottom ${classes}`}
      ref={swiperContainer}
    >
      <Swiper {...swiperSettings}>
        {slides &&
          slides.map((slide, i) => {
            return (
              <SwiperSlide
                className="mobile-preview-slider__slide"
                onClick={e => onSlideClick(e, i)}
                key={slide._key ? slide._key : slide._id}
              >
                {renderSlideItem(slide)}
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}

export default MobilePreviewSlider
