import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import SingleColumnArtistListBlock from './single-column-artist-list-block'

export const artistsQuery = graphql`
  query WarpCArtistListBlockQuery {
    artists: allSanityArtist(
      sort: { fields: [title], order: ASC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true }, isIndexed: { eq: true } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...SanityImage
            alt
          }
          title
          categories {
            _id
            title
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

class WarpCArtistListBlock extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StaticQuery
        query={artistsQuery}
        render={data => {
          const artistNodes = (data || {}).artists
            ? mapEdgesToNodes(data.artists).filter(filterOutDocsWithoutSlugs)
            : []
          return (
            <SingleColumnArtistListBlock
              isHomepageView={this.props.isHomepageView}
              artistNodes={artistNodes}
            />
          )
        }}
      ></StaticQuery>
    )
  }
}

export default WarpCArtistListBlock
