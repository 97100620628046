import React from 'react'
import ImageLoader from './image-loader'
import { getArtistUrl, isBrowser } from '../lib/helpers'
import { Link } from 'gatsby'
import DOMPurify from 'dompurify'
import cx from 'classnames'

const ArtistsParallaxItem = props => {
  const { title, previewImage, slug, hoveredItemIndex, index, useMouseEvents } = props
  const _title = isBrowser() ? DOMPurify.sanitize(title) : title
  const containerClasses = cx('artists-parallax-item', {
    'is-hover-active': hoveredItemIndex !== null,
    'is-current': index === hoveredItemIndex,
  })

  return (
    <Link
      to={getArtistUrl(slug.current)}
      className={containerClasses}
      data-index={index}
      onMouseEnter={useMouseEvents ? () => props.onMouseEnterItem(index) : null}
      onMouseLeave={useMouseEvents ? () => props.onMouseLeaveItem() : null}
    >
      <div
        className="artists-parallax-item__title text-m text-caps"
        data-title={title}
        dangerouslySetInnerHTML={{ __html: _title }}
      />
      <ImageLoader imageData={previewImage} widths={[320, 375, 750]} noBg />
    </Link>
  )
}

export default ArtistsParallaxItem
