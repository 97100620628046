import React from 'react'
import { format } from 'date-fns'
import { isWarpC } from '../lib/helpers'
import Slider from './slider'
import WarpLogo from './warp-logo'
import cx from 'classnames'

function NewsTickerBlock(props) {
  const { items } = props
  const filteredItems = items.filter(item => !item.hidden)
  const containerClasses = cx('news-ticker-block', { 'no-border-top no-border-bottom': isWarpC() })

  return filteredItems.length > 0 ? (
    <Slider className={containerClasses}>
      <ul>
        {filteredItems.map((item, index) => (
          <li key={item._key} className="news-ticker-block__entry">
            <a href={item.url} target="_blank">
              <ul>
                <li className="news-ticker-block__entry__date text-xs text-caps mob-m-1">
                  <time dateTime={`${format(item.date, 'YYYY-MM-DD')} 12:00`}>
                    {`news_${format(item.date, 'DD/MM/YY')}`}
                  </time>
                </li>
                <li className="news-ticker-block__entry__body text-m-plus text-caps">
                  <em className="news-ticker-block__entry__headline">{item.headline}</em>
                  <br />
                  {item.subHeadline}
                </li>
              </ul>
            </a>
          </li>
        ))}
      </ul>
    </Slider>
  ) : (
    <div className="news-ticker-block placeholder">
      <WarpLogo color="white" />
    </div>
  )
}

export default NewsTickerBlock
