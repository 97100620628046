import React from 'react'
import HeadlineColumn from './headline-column'
import ColumnInfoEntries from './column-info-entries'

const HeadlinedColumnBlock = props => {
  return (
    <div className="headlined-column-block columns" id={props.id ? props.id : ''}>
      <HeadlineColumn>
        <h2 className="arrowDownRight--left text-l text-caps mob-pt-1 mob-pl-1 mob-pr-1 mob-pb-0_5 tab-p-2_4">
          {props.leftColumn.title}
        </h2>
      </HeadlineColumn>
      <div className="column is-half">
        <div className="mob-p-1 mob-pb-1_3 tab-p-2_4">
          <ColumnInfoEntries entries={props.rightColumn} />
        </div>
      </div>
    </div>
  )
}

export default HeadlinedColumnBlock
