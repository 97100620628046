import React from 'react'
import SingleColumnArtistListBlock from './single-column-artist-list-block'

const SelectedArtistsBlock = props => {
  const { items, isHomepageView, linkToAll } = props
  return items ? (
    <SingleColumnArtistListBlock
      artistNodes={items}
      isHomepageView={isHomepageView}
      linkToAll={linkToAll}
    />
  ) : (
    <></>
  )
}

export default SelectedArtistsBlock
