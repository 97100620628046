import { Link } from 'gatsby'
import React from 'react'

function HeadlineLinking(props) {
  const { headlineContent, headlineContentShort, buttonContent, buttonTo, darkTheme } = props
  // composers google preview
  const noSnippet = headlineContent && headlineContent.toLowerCase() === 'composers'

  return (
    <div className={`headline-linking ${darkTheme ? 'dark-theme' : ''}`}>
      <Link
        to={buttonTo}
        className="headline-linking__title text-l text-caps mob-ml-1 mob-mr-1 mob-mb-0_2 mob-mt-0_2"
      >
        {noSnippet ? (
          <>
            <h2
              data-nosnippet
              className="headline-linking__title__content headline-linking__title__content--long"
            >
              {headlineContent}
            </h2>
            <h2
              data-nonippet
              className="headline-linking__title__content headline-linking__title__content--short"
            >
              {headlineContentShort ? headlineContentShort : headlineContent}
            </h2>
          </>
        ) : (
          <>
            <h2 className="headline-linking__title__content headline-linking__title__content--long">
              {headlineContent}
            </h2>
            <h2 className="headline-linking__title__content headline-linking__title__content--short">
              {headlineContentShort ? headlineContentShort : headlineContent}
            </h2>
          </>
        )}
      </Link>
      <Link to={buttonTo} className="rounded-button --link-internal mob-m-1 mob-mb-0_5-i tab-m-1">
        <span className="headline-linking__button headline-linking__button--long">
          {buttonContent}
        </span>
        <span className="headline-linking__button headline-linking__button--short">All</span>
      </Link>
    </div>
  )
}

export default HeadlineLinking
