import React from 'react'
import cx from 'classnames'
import CTALink from './CTALink'

function CTATile(props) {
  const { title, route, landingPageRoute, link } = props
  return (
    <CTALink className="CTA-tile text-l text-caps is-white-on-black hover-area--link" route={route} landingPageRoute={landingPageRoute} link={link}>
      <span className="--link-internal">{title}</span>
    </CTALink>
  )
}

export default CTATile
