import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'

function TextColumn(props) {
  const { title, body } = props
  return (
    <div className="column is-half">
      {/* {title && <h3>{title}</h3>}; */}
      {body && (
        <BasePortableText blocks={body} serializers={serializers} {...clientConfig.sanity} />
      )}
    </div>
  )
}

export default TextColumn
