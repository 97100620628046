import React from 'react'
import { getSafeColumn, inheritClassNames } from '../lib/helpers'

const FilterBar = props => {
  const { categories, filter, onClick } = props

  const renderButton = (category, cols, multiline = false) => {
    const key = multiline ? `filter-bar-multiline-${category._id}` : `filter-bar-${category._id}`
    return (
      <div
        className={`filter-bar__button column no-auto-border ${getSafeColumn(12 / cols.length)} ${
          filter === category._id ? 'active' : ''
        }`}
        key={key}
        onClick={() => {
          onClick(category._id)
        }}
        role="button"
      >
        <div className="mob-ml-1 mob-mr-1">{category.title}</div>
      </div>
    )
  }

  const renderSingleLine = () => {
    return (
      <div
        className={`filter-bar columns stays-horizontal text-m-minus text-caps ${inheritClassNames(
          props
        )}`}
      >
        {categories.map(category => renderButton(category, categories))}
      </div>
    )
  }

  const renderMultiLine = () => {
    const groups = []
    let group = []
    const getGroupMax = size => {
      switch (size) {
        case size % 3 === 0:
          return 3
        case 5 || 7:
          return 3
        default:
          return 4
      }
    }
    const groupMax = getGroupMax(categories.length)
    categories.forEach((category, i) => {
      group.push(category)
      if (group.length === groupMax || i === categories.length - 1) {
        groups.push(group)
        group = []
      }
    })

    return (
      <div
        className={`filter-bar filter-bar--mob-multiline columns text-m-minus text-caps ${inheritClassNames(
          props
        )}`}
      >
        {groups.map((group, i) => (
          <div className="filter-bar__row columns stays-horizontal" key={`filter-bar-row--${i}`}>
            {group.map(category => renderButton(category, group, true))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {renderSingleLine()}
      {renderMultiLine()}
    </>
  )
}

export default FilterBar
