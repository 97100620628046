import React from 'react'
import CTALink from './CTALink'

function LinksBlock(props) {
  const { links } = props
  return (
    <div>
      <ul>
        {links &&
          links.map((link, i) => (
            <li key={link._key}>
              <CTALink {...link} />
            </li>
          ))}
      </ul>
    </div>
  )
}

export default LinksBlock
