import React, { useEffect, useRef, useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import clientConfig from '../../client-config'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import ListBlockTooltip from './list-block-tooltip'
import FilterBar from './filter-bar'
import { firstLetterUppercased } from '../lib/helpers'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import BusinessCard from './business-card'

export const artistsQuery = graphql`
  query WarpPArtistListBlockQuery {
    artists: allSanityArtist(
      sort: { fields: [title], order: ASC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true }, isIndexed: { eq: true } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...SanityImage
            alt
          }
          title
          categories {
            _id
            title
          }
          _rawExcerpt
          slug {
            current
          }
          hidePage
        }
      }
    }
  }
`

function LetterGroup(props) {
  const { group, groupKey, filter, onMouseEnter, onMouseLeave, top } = props
  const containerRef = useRef()

  return (
    <li
      ref={containerRef}
      className={`warp-p-artist-list-block__group mob-pl-1 mob-pr-1 tab-pl-2_4 tab-pr-2_4 pb-1em`}
      data-group={groupKey}
    >
      <ul>
        {group.map(artist => {
          let active
          if (filter === null) {
            active = true
          } else {
            active = artist.categories.some(category => filter === category._id)
          }
          let callbacks = {}
          if (!isMobile && artist.previewImage) {
            callbacks.onMouseEnter = () => {
              if (artist.previewImage.asset) {
                onMouseEnter(artist.previewImage.asset._id, artist.title)
              }
            }
            callbacks.onMouseLeave = onMouseLeave
          }
          return (
            <li
              key={artist.id}
              className={`warp-p-artist-list-block__group__entry${classNames({
                '--inactive': !active,
              })}`}
            >
              {artist.hidePage ? (
                <span {...callbacks}>{artist.title}</span>
              ) : (
                <Link to={`/artists/${artist.slug.current}`} {...callbacks}>
                  {artist.title}
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </li>
  )
}

const otherGroupName = '123#@!'

const WarpPArtistListBlock = () => {
  const [currentImageData, setCurrentImageData] = React.useState(null)
  const [currentArtistName, setCurrentArtistName] = React.useState(null)
  const [isMouseTooltipVisible, setIsMouseTooltipVisible] = React.useState(false)
  const [tooltipPos, setTooltipPos] = React.useState('top')
  const [top, setTop] = React.useState(0)
  const [filter, setFilter] = React.useState(null)
  const containerRef = React.useRef()

  const onMouseEnterArtistName = (currentImageId, newCurrentArtistName) => {
    setCurrentImageData(
      getGatsbyImageData(
        currentImageId,
        {
          width: 500,
          fit: 'fillmax',
          placeholder: 'blurred',
        },
        clientConfig.sanity
      )
    )
    setCurrentArtistName(newCurrentArtistName)
    setIsMouseTooltipVisible(true)
  }

  const handleOnResize = () => {
    const { top } = containerRef.current.getBoundingClientRect()
    setTop(top)
  }

  useEffect(() => {
    handleOnResize()
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [])

  const onMouseLeaveArtistName = () => {
    // this.setState({ currentImageData: null, isMouseTooltipVisible: false }) // won't throw warning, safe?
    setIsMouseTooltipVisible(false)
  }

  const updateTooltipPos = e => {
    if (e.clientY > window.innerHeight / 2) {
      setTooltipPos('bottom')
    } else {
      setTooltipPos('top')
    }
  }

  const handleFilterButtonCallback = _id => {
    setFilter(_id)
  }

  return (
    <StaticQuery
      query={artistsQuery}
      render={data => {
        const artistNodes = (data || {}).artists
          ? mapEdgesToNodes(data.artists).filter(filterOutDocsWithoutSlugs)
          : []

        const categories = artistNodes.reduce(
          (collector, artist) => {
            artist.categories.forEach(category => {
              const isIn = collector.some(existingCategory => category._id === existingCategory._id)
              if (!isIn) {
                collector.push({ _id: category._id, title: category.title })
              }
            })
            return collector
          },
          [{ _id: null, title: 'All' }]
        )

        let categoryNodesGrouped = artistNodes.reduce((collector, currentValue) => {
          let key = firstLetterUppercased(currentValue.title)
          if (key.toUpperCase() === key.toLowerCase()) {
            key = otherGroupName
          }
          if (!(key in collector)) {
            collector[key] = []
          }
          collector[key].push(currentValue)
          return collector
        }, {})
        if (otherGroupName in categoryNodesGrouped) {
          const otherGroup = categoryNodesGrouped[otherGroupName]
          delete categoryNodesGrouped[otherGroupName]
          categoryNodesGrouped[otherGroupName] = otherGroup
        }

        const containerClasses = classNames({
          '--inactive': filter !== null,
          'is-tooltip-pos-top': tooltipPos === 'top',
        })
        return (
          <div className="warp-p-artist-list-block">
            <FilterBar
              className="border-bottom-single"
              categories={categories}
              onClick={handleFilterButtonCallback}
              filter={filter}
            />
            <div className="warp-p-artist-list-block__outer-wrapper no-border-top">
              <div className="warp-p-artist-list-block__background no-border-top columns">
                {[...Array(6)].map((_, index) => (
                  <div key={index} className="column"></div>
                ))}
              </div>
              <div className="warp-p-artist-list-block__wrapper no-border-top mob-pt-1 tab-pt-2 tab-pb-1">
                <ul
                  ref={containerRef}
                  className={`warp-p-artist-list-block__list no-border-top text-m-plus ${containerClasses}`}
                  onMouseMove={e => updateTooltipPos(e)}
                >
                  {Object.keys(categoryNodesGrouped).map(key => (
                    <LetterGroup
                      key={key}
                      group={categoryNodesGrouped[key]}
                      groupKey={key}
                      filter={filter}
                      onMouseEnter={onMouseEnterArtistName}
                      onMouseLeave={onMouseLeaveArtistName}
                      top={top}
                    ></LetterGroup>
                  ))}
                  <ListBlockTooltip
                    currentImageData={currentImageData}
                    isMouseTooltipVisible={isMouseTooltipVisible}
                    currentArtistName={currentArtistName}
                  />
                </ul>
                <BusinessCard id="list-view"></BusinessCard>
              </div>
            </div>
          </div>
        )
      }}
    ></StaticQuery>
  )
}

export default WarpPArtistListBlock
