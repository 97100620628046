import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import cx from 'classnames'

function ListBlockTooltip(props) {
  const {
    currentImageData,
    currentPlaceholderImageData,
    isMouseTooltipVisible,
    currentTitle,
  } = props
  const [imageLoaded, setImageLoaded] = useState(false)

  const isPortrait = imageData => {
    const { width, height } = imageData
    return width / height < 1
  }

  // useEffect(() => {
  //   if (currentImageData !== props.currentImageData) {
  //     setImageLoaded(false)
  //   }
  // }, [currentImageData])

  return (
    <MouseTooltip
      className={cx('mouse-tooltip list-block__hover-image-container no-border-top', {
        'is-tooltip-visible': isMouseTooltipVisible,
        'is-image-loaded': imageLoaded,
      })}
      visible={isMouseTooltipVisible}
      offsetX={15}
      offsetY={0}
    >
      {currentImageData ? (
        <>
          <GatsbyImage
            className={cx('list-block__hover-image list-block__hover-image--main', {
              'is-portrait': currentImageData && isPortrait(currentImageData),
            })}
            image={currentImageData}
            alt={`Tooltip preview image for ${currentTitle}`}
            // onLoad={() => setImageLoaded(true)}
          />
        </>
      ) : (
        <></>
      )}
    </MouseTooltip>
  )
}

export default ListBlockTooltip
