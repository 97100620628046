import React from 'react'
import CustomColumn from './custom-column'

function CustomColumnsBlock({ columns = [] }) {
  return (
    <div className="columns tab-mb-0 custom-column-block grow-last-vertically">
      {columns.map((column, index) => (
        <CustomColumn
          key={column._key}
          {...column}
          className={`column ${'is-' + 12 / columns.length}`}
        />
      ))}
    </div>
  )
}

export default CustomColumnsBlock
