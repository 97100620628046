import React, { useRef } from 'react'
import MusicGridItem from './music-grid-item'
import MobilePreviewSlider from './mobile-preview-slider'
import LoadMore from './load-more'
import Slider from './slider'

function MusicGridBlock(props) {
  const { title, items, kind } = props
  const gridContainerRef = useRef()
  const mobileSliderItems = items.filter((_, index) => index < 4)
  const isSlider = ['monthly', 'releases'].includes(kind)
  const content = items.map((item, index) => {
    if (item && kind) {
      const itemProps = Object.assign(
        {
          kind,
        },
        item
      )
      return (
        <MusicGridItem
          className={`grid-item border-top-single column border-left-single no-margins`}
          {...itemProps}
          key={`music-grid_${item?._key ?? item.id}_${index}`}
        />
      )
    }
  })

  return (
    <div className="music-grid-block">
      <h3 className="music-grid-block__title text-l text-caps mob-m-1 mob-mt-1_2">{title}</h3>
      <div
        ref={gridContainerRef}
        className={`music-grid-block__entries music-grid-block__entries--desktop columns is-multiline grow-out`}
      >
        {isSlider ? (
          <Slider>{content}</Slider>
        ) : (
          <LoadMore initial={4} step={4} showButton={true}>
            {content}
          </LoadMore>
        )}
      </div>
      <MobilePreviewSlider
        className="music-grid-block__entries music-grid-block__entries--mobile"
        slides={mobileSliderItems}
        sliderType="musicGrid"
        musicGridKind={kind}
      />
    </div>
  )
}

export default MusicGridBlock
