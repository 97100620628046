import React from "react";
import clientConfig from "../../client-config";
import BasePortableText from "@sanity/block-content-to-react";
import defaultSerializers from "./serializers";

const PortableText = ({ blocks, serializers=defaultSerializers, ...kwargs }) => {
  return <BasePortableText blocks={blocks} serializers={serializers} {...clientConfig.sanity} {...kwargs}/>
};

export default PortableText;
