import React, { Component } from 'react'
import CustomColumn from './custom-column'
import StackingColumnRow from './stacking-column-row'

const StackingColumn = props => {
  return (
    <div className={`stacking-column column ${props.className || ""}`}>
      {props.column.items.map((entry, index) => {
        let el = null
        switch (entry._type) {
          case 'customColumn':
            el = <CustomColumn className="border-top" {...entry} hideColon={true} />
            break
          case 'stackingColumnRow':
            el = <StackingColumnRow className="border-top" {...entry} />
            break
          default:
            throw new Error('stacking column not found component')
        }
        return <React.Fragment key={entry._key}>{el}</React.Fragment>
      })}
    </div>
  )
}

export default StackingColumn
