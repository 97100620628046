import React, { Component } from 'react'
import classNames from 'classnames'
import PortableText from './portableText'
import { inheritClassNames } from '../lib/helpers'

class StackingColumnRow extends Component {
    constructor(props) {
      super(props)
      this.state = {
        showMore: true,
      }
      this.bodyRef = React.createRef()
      this.handleShowMoreCallback = this.handleShowMoreCallback.bind(this)
    }

    handleShowMoreCallback() {
      // document.documentElement.scrollBy(0, -1)
      const heightFrom = this.bodyRef.current.clientHeight
      const lineHeight = parseFloat(window.getComputedStyle(this.bodyRef.current).getPropertyValue('line-height'))
      // const numberOfLines = parseInt(window.getComputedStyle(this.bodyRef.current).getPropertyValue('-webkit-line-clamp'))
      const heightTo = this.state.showMore ? this.bodyRef.current.scrollHeight : lineHeight * 3
      
      this.bodyRef.current.style.height = `${heightFrom}px`
      this.bodyRef.current.classList.add('--transitioning')
      const transitionDuration = parseFloat(window.getComputedStyle(this.bodyRef.current).getPropertyValue('transition-duration'))

      setTimeout(() => {
        this.bodyRef.current.style.height = `${heightTo}px`
      }, 1)

      setTimeout(() => {
        this.setState({ showMore: !this.state.showMore })
        this.bodyRef.current.classList.remove('--transitioning')
        this.bodyRef.current.style.removeProperty('height')
      }, transitionDuration * 1000)    
    }
  
    render() {
      const bodyClasses = classNames({
        '--showing-less': this.state.showMore && this.props.showMoreButton
      })
      return (
        <div
          key={this.props.key}
          className={`
          ${inheritClassNames(this)}
          stacking-column-row
          mob-pt-1 mob-pr-1 mob-pl-1 mob-pb-2_4
          tab-p-2_4 tab-pb-0
        `}>
          <h3 className="text-caps ">{this.props.title}</h3>
          <div ref={this.bodyRef} className={`stacking-column-row__content text-m-plus ${bodyClasses}`}>
            <PortableText blocks={this.props.body}/>
          </div>
          {this.props.showMoreButton && (
            <button className="rounded-button" onClick={this.handleShowMoreCallback}>
              {"show " + (this.state.showMore ? 'more' : 'less')}
            </button>
          )}
        </div>
      )
    }
  }

export default StackingColumnRow
